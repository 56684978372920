<template>
  <a-modal
    title="协议编辑"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="协议内容" has-feedback :labelCol="labelCol_1" :wrapperCol="wrapperCol_1">
              <antd-editor
                :uploadConfig="editorUploadConfig"
                v-model="editorContent"
                @onchange="changeEditor"
                @oninit="getEditor"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import {  agreeDetail, agreeEdit } from '@/api/modular/mallLiving/agreeFeed'
import { AntdEditor } from '@/components'
import {uploadFile} from "@/api/modular/mallLiving/uploadFile";


export default {
  components: {
    AntdEditor,
  },
  data() {
    return {
      noticeDetail: [],
      editorUploadConfig: {
        method: 'http',
        callback: this.editorUploadImage,
      },
      editorContent: '',
      editorContentText: '',
      previewVisible: false,
      previewImage: '',
      fileList: [],
      item: '',
      categoryList: [],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelCol_1: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol_1: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
      videoLoading: false,
      thumbnails: '',
      count: 1,
      visible: false,
      confirmLoading: false,
      orgTree: [],
      orgList: [],
      posList: [],
      sysEmpParamExtList: [],
      memberLoading: false,
      form: this.$form.createForm(this),
      data: [],
      birthdayString: '',
      name: undefined,
      nickName: undefined,
      account: undefined,
      schoolName: '',
      commodityId: '',
      cos: null,
    }
  },
  methods: {
    editorUploadImage(files, insert) {
      files.forEach((file) => {
        uploadFile(file.name, file, {
          progress: function (p, checkpoint) {
            const percentage = Math.floor(p * 100)
          },
        }).then((res) => {
          console.log('res', res)
          if (res.res.statusCode == 200) {
            const list = res.res.requestUrls[0].split('?');
            if (list.length > 0) {
              insert( list[0])
            }
          }
        })
        
      })
    },
    getEditor(editor) {
      this.editor = editor
    },
    changeEditor(html, ele) {
      this.editorContent = html
      this.editorContentText = ele.text()
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    },

    // 初始化方法
    edit(record) {
      let that = this;
        that.editorContent = "";
      //后面是正常初始化
      this.confirmLoading = false
      this.visible = true
      this.agreementId = record.id
      this.getAgrementDetail(record.id)
    },

    getAgrementDetail(id) {
      let that = this
      that.confirmLoading = true
      agreeDetail({ id: id }).then((res) => {
        if (res.success) {
          setTimeout(() => {
            that.editor.txt.html(res.data.content)
            that.editorContent = res.data.content
            that.confirmLoading = false
          }, 100)
        }
      }).finally(err =>{
        setTimeout(function(){
          that.confirmLoading = false
        },500)
      })
    },
    handleSubmit() {
      const {
        form: { validateFields },
      } = this
      validateFields((errors, values) => {
        if (!errors) {
          agreeEdit({ id: this.agreementId, content: this.editorContent}).then(res =>{
            if(res.success){
              this.$message.success('编辑成功')
              this.confirmLoading = false
              this.$emit('ok', values)
              this.handleCancel()
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.previewVisible = false
      this.form.resetFields()
      this.visible = false
    },
  },
}
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>