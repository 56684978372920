//协议和意见反馈相关接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-14
 */

//协议分页查询
export function agreePageList(parameter){
    return axios({
        url: '/agreement/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//协议详情
export function agreeDetail(parameter){
    return axios({
        url: '/agreement/detail',
        method: 'post',
        params: parameter 
    })
}


//协议编辑
export function agreeEdit(parameter){
    return axios({
        url: '/agreement/edit',
        method: 'post',
        data: parameter
    })
}






//意见反馈分页查询
export function feedPageList(parameter){
    return axios({
        url: '/feedback/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//意见反馈详情
export function feedDetail(parameter){
    return axios({
        url: '/feedback/detail',
        method: 'post',
        params: parameter 
    })
}
//意见反馈已读
export function feedRead(parameter){
    return axios({
        url: '/feedback/read',
        method: 'post',
        data: parameter 
    })
}
